body{
  -webkit-font-smoothing: antialiased;
}

a{
  color: #87807C!important;
  text-decoration: none!important;
}

a:focus,
a:hover {
  color: #606c76!important;
}

.btn{
  border-radius: 0!important;
}

.logo-tfg {
  background: #fff;
line-height: 60px;
font-size: 18px;
color: #fff;
text-align: center;
height: 60px;
margin-right: 1px;
font-weight: 700;
display: block;
width: 100%;
border-bottom: 1px solid #e4ebef;
border-right: 1px solid #e4ebef;
}

.account-wall {
  margin: 5rem 0;
  padding: 40px 20px;
  background-color: #4E1849!important;
  text-align: center;
}

.sidebar {
  position: fixed;
  overflow: auto;
  top: 0;
  bottom: 0;
  left: 0;
  width: 20%;
  background-color: #efefef;
}

.content {
  padding-left: 20%;
  height: 100%;
}

.top__header {
  position: fixed;
  top: 0;
  left: 20%;
  right: 0;
  height: 60px;
  background-color: #fff;
  z-index: 200;
  border-bottom: 1px solid #e4ebef;
  padding-left: 24px;
  padding-right: 24px;
}

.top__header__title {
  margin: 0;
  line-height: 60px;
  font-size: 18px;
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.top__main {
  padding: 30px;
  margin-top: 60px;
}

/* Forms */
.form-control{
  border-radius: 0!important;
  border: 1px solid #EEEEEE;
  -webkit-appearance: none;
  font-family: europa, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #666666;
}

.form-control:focus{
  border-color: #EEEEEE;
  color: #000000;
  box-shadow: 0 0 1px 1px rgba(0,0,0,0.25);
}

.dark .form-control{
  border: 1px solid #000000;
}

.dark .form-control:focus{
  background-color: #f1f1f1;
}

label{
  font-size: 12px;
  font-family: europa, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #666666;
}

select:not([multiple]) {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: right 50%;
    background-repeat: no-repeat;
    background-image: url(data:image/svg;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
    padding: .5em;
    padding-right: 1.5em
}

.form-check-input{
  margin-top: .35rem;
}